<template>
  <div>
    <material-card
      color="general"
      :title="$t('Customer.customer_prefs')"
      :text="$t('Customer.manage_customer_prefs')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t("Common.new_item") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-format-list-bulleted-square
            </v-icon>
            {{ $t("Customer.manage_customer_prefs") }}
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm6
                  md4
                >
                  <v-text-field
                    v-model="editedItem.name"
                    :label="$t('Common.name')"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md4
                >
                  <v-text-field
                    v-model="editedItem.description"
                    :label="$t('Common.description')"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :loading="isSaving"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t("Common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        v-if="spinnerActive"
        class="table-spinner-container"
      >
        <v-progress-circular
          color="primary"
          indeterminate
          :size="100"
          :width="8"
        />
      </div>
      <div v-else>
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="customerPreferenceList"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip
              v-if="role === 'ADMIN' || role === 'SUPER_ADMIN'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="role === 'ADMIN' || role === 'SUPER_ADMIN'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.delete") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultPopup from "../../components/DefaultPopup.vue";

export default {
  components: {
    DefaultPopup,
  },
  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
    },
    defaultItem: {
      name: "",
      description: "",
    },
    role: "",
    alertTextDelete: "",
    spinnerActive: false,
    isSaving: false,
  }),

  computed: {
    ...mapGetters("customer_preferences", ["customerPreferenceList"]),

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    headers() {
      return [
        { text: this.$t("Common.name"), value: "name" },
        { text: this.$t("Common.description"), value: "description" },
        { text: this.$t("Common.actions"), value: "actions",  sortable: false},
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  // lifecycle hooks
  created() {
    this.initialize();

    this.role = this.$store.state.role;
  },

  beforeDestroy() {
    this.clearCustomerPreferenceState();
  },

  methods: {
    ...mapActions("customer_preferences", [
      "getCustomerPreferenceList",
      "createCustomerPreference",
      "updateCustomerPreference",
      "deleteCustomerPreference",
      "clearCustomerPreferenceState",
    ]),

    async initialize() {
      this.spinnerActive = true;
      const company_id = this.$store.state.company_id;
      if (this.role === "SUPER_ADMIN") await this.getCustomerPreferenceList();
      else await this.getCustomerPreferenceList(company_id);
      this.spinnerActive = false;
    },

    editItem(item) {
      this.editedIndex = this.customerPreferenceList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteCustomerPreference(this.deleteModalItemId)
        if (isDeleted) {
          this.isDeleteModalVisible = false
        } else {
          this.alertTextDelete = this.$t('CustomerPreference.alerts.error-delete')
        }
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async save() {
      this.isSaving = true;
      if (this.editedIndex > -1) {
        const isUpdated = await this.updateCustomerPreference(this.editedItem);
        if (isUpdated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("Customer.alerts.error-edit");
        }
      } else {
        const isCreated = await this.createCustomerPreference(this.editedItem);
        if (isCreated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("Customer.alerts.error-create");
        }
      }
      this.isSaving = false;
    },
  },
};
</script>
